import * as API from "@/game-lol/apis/api.mjs";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

function fetchData(_params, searchParams) {
  const promises = [
    getEntriesByTags(
      [CONTENTFUL_TAGS.TILES_GRID, CONTENTFUL_TAGS.PROBUILDS],
      4,
    ),
    getEntriesByTags(
      [CONTENTFUL_TAGS.TILES_LIST, CONTENTFUL_TAGS.PROBUILDS],
      4,
    ),
  ];

  promises.push(
    API.PROBUILDS.getProMatchHistory({
      role: searchParams.role,
    }),
  );

  return Promise.all(promises);
}

export default fetchData;
